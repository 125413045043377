<template>
  <div>
    <v-card>
      <v-app-bar dark color="primary">
        <v-icon large class="mr-3">data_usage</v-icon>
        <v-toolbar-title>User Overview</v-toolbar-title>
      </v-app-bar>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12">
            <v-autocomplete
              prepend-icon="list"
              label="Vendor Code"
              :items="projectCodes"
              :item-text="(item) => `${item.name} - ${item.projectCode}`"
              item-value="projectCode"
              v-model="dataToSend.projectCodes"
              v-validate="'required'"
              required
              multiple
              chips
              data-vv-name="Vendor Code"
              :error-messages="errors.collect('Vendor Code')"
            >
              <v-list-item slot="prepend-item" ripple @click="selectAll">
                <v-list-item-action>
                  <v-icon
                    :color="
                      dataToSend.projectCodes.length > 0
                        ? 'primary darken-4'
                        : ''
                    "
                    >{{ icon }}</v-icon
                  >
                </v-list-item-action>
                <v-list-item-title>
                  <div v-if="!allSelected">Select All</div>
                  <div v-else>Unselect All</div>
                </v-list-item-title>
              </v-list-item>
              <v-divider slot="prepend-item" class="mt-2"></v-divider>
              <v-divider slot="append-item" class="mb-2"></v-divider>
            </v-autocomplete>
          </v-col>

          <v-col>
            <v-menu
              ref="startDateMenu"
              v-model="startDateMenu"
              :close-on-content-click="false"
              :return-value.sync="startDate"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dataToSend.startDate"
                  label="From"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="primary"
                no-title
                scrollable
                v-model="dataToSend.startDate"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.startDateMenu.save(Date(startDate))"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col>
            <v-menu
              ref="endDateMenu"
              v-model="endDateMenu"
              :close-on-content-click="false"
              :return-value.sync="endDate"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  label="To"
                  v-model="dataToSend.endDate"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>

              <v-date-picker
                no-title
                color="primary"
                scrollable
                v-model="dataToSend.endDate"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.endDateMenu.save(Date(endDate))"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row class="justify-center align-center" no-gutters>
          <v-btn class="ma-2" @click="loadData()" color="primary">Load</v-btn>
          <v-btn class="ma-2" @click="clearFilter()" color="primary"
            >Clear Filter</v-btn
          >
          <v-btn
            class="ma-2"
            @click="exportAllUsers()"
            color="primary"
            :loading="loading"
          >
            <v-icon class="mr-2">cloud_download</v-icon>Export
          </v-btn>
        </v-row>

        <v-row>
          <v-col cols="12" md="6" sm="12">
            <v-card color="#385F73" dark height="200px">
              <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title class="font-weight-thin ml-3">
                  Total User Count
                  <h2 class="font-weight-light ml-3">
                    <number
                      :from="0"
                      :to="totalUsers"
                      :format="(number) => number.toFixed(0)"
                      :duration="2"
                      :delay="1"
                      easing="Power3.easeOut"
                    />
                  </h2>
                </v-card-title>
                <v-avatar size="100" tile>
                  <v-icon x-large>group</v-icon>
                </v-avatar>
              </div>
            </v-card>
          </v-col>

          <!-- <v-col cols="12" md="6" sm="12">
            <v-card color="#1B998B" dark height="200px">
              <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title class="font-weight-thin ml-3">
                  Total Active Users Based on Task
                  <h2 class="font-weight-light ml-3">
                    <number
                      :from="0"
                      :to="activeUsers"
                      :format="number => number.toFixed(0)"
                      :duration="2"
                      :delay="1"
                      easing="Power3.easeOut"
                    />
                  </h2>
                </v-card-title>
                <v-avatar size="100" tile>
                  <v-icon x-large>done_all</v-icon>
                </v-avatar>
              </div>
            </v-card>
          </v-col> -->

          <v-col cols="12" md="6" sm="12">
            <v-card color="#656176" dark height="200px">
              <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title class="font-weight-thin ml-3">
                  Last User Joined Date
                  <h2
                    v-if="lastDate.length > 0 && lastDate != 'No User Found'"
                    class="font-weight-light ml-3"
                  >
                    {{ $moment(lastDate).format("DD-MM-YYYY") }}
                  </h2>
                  <h2
                    v-if="lastDate === 'No User Found'"
                    class="font-weight-light ml-3"
                  >
                    {{ lastDate }}
                  </h2>
                </v-card-title>
                <v-avatar size="100" tile>
                  <v-icon x-large>calendar_today</v-icon>
                </v-avatar>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>Users</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-text-field
            v-model="searchCriteria.text"
            append-icon="search"
            clearable
            clear-icon="mdi-close-circle"
            label="Search with Email Id, Name or Mobile Number"
            auto-grow
            outlined
            rows="1"
            row-height="5"
          ></v-text-field>
          <v-btn color="primary" @click="searchRespondent">Search</v-btn>
          <v-data-table
            :headers="headers"
            :items="users"
            item-key="name"
            :search="search"
          ></v-data-table>
          <div class="text-xs-center pt-2">
            <v-pagination
              color="primary"
              v-model="pagination.page"
              :length="Math.ceil(totalDocs / 10)"
              :total-visible="7"
              @input="(pageNumber) => loadUsers(pageNumber)"
            ></v-pagination>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import DataServiceVD from "./../services/dataServiceVD.js";

const dataServiceVD = new DataServiceVD();

export default {
  data() {
    return {
      projectCodes: [],
      totalUsers: 0,
      pagination: {},
      searchCriteria: {},
      headers: [
        { text: "Email", value: "email", sortable: true },
        { text: "Phone", value: "mobileNumber", sortable: true },
        { text: "Name", value: "name", sortable: true },
        { text: "User Id", value: "userId", sortable: true },
        { text: "Account Status", value: "status", sortable: true },
      ],
      users: [],
      totalDocs: 0,
      nextPage: 1,
      userList: {},
      search: "",
      lastDate: [],
      activeUsers: 0,
      startDate: null,
      endDate: null,
      startDateMenu: false,
      endDateMenu: false,
      loading: false,
      selected: false,
      snack: false,
      dataToSend: {
        projectCodes: [],
        startDate: undefined,
        endDate: undefined,
      },
    };
  },
  mounted() {
    this.nextPage = 1;
    let vendorUser = this.$store.state.vendorUser;
    let dataToSend = {};

    if (
      vendorUser &&
      vendorUser.roles &&
      vendorUser.roles.includes("VENDOR") &&
      !vendorUser.roles.includes("ADMIN")
    ) {
      dataToSend.userId = vendorUser.userId;
    }

    dataServiceVD.getVendorCodes(dataToSend).then((res) => {
      this.projectCodes = res.data;
      this.$disableLoader();
    });
  },

  computed: {
    allSelected() {
      return this.dataToSend.projectCodes.length === this.projectCodes.length;
    },
    someSelected() {
      return this.dataToSend.projectCodes.length > 0 && !this.allSelected;
    },
    icon() {
      if (this.allSelected) return "mdi-close-box";
      if (this.someSelected) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },

  methods: {
    selectAll() {
      this.dataToSend.projectCodes = this.allSelected
        ? []
        : this.projectCodes.map((r) => r.projectCode);
    },

    async getTotalUsers(params) {
      this.$setLoader();
      let res = await dataServiceVD.getTotalUserCount(params);
      this.totalUsers = res.data.count;
      this.$disableLoader();
    },

    async getLastUserJoinedDate(params) {
      let res = await dataServiceVD.getLastUserJoinedDate(params);
      this.lastDate =
        undefined == res.data.date ? "No User Found" : res.data.date.created_at;
    },

    async getActiveUsers() {
      let res = await dataServiceVD.countActiveUsers({
        projectCode: this.dataToSend.projectCode,
        type: "count",
      });
      this.activeUsers = res.data.count;
    },

    clearFilter() {
      this.dataToSend.startDate = undefined;
      this.dataToSend.endDate = undefined;
    },

    async loadData() {
      let v = await this.$validator.validateAll();
      if (v) {
        this.getTotalUsers(this.dataToSend);
        this.getLastUserJoinedDate(this.dataToSend);
        this.loadUsers();
      }
    },

    async exportAllUsers() {
      let v = await this.$validator.validateAll();
      if (v) {
        (this.dataToSend.email = this.$store.state.userProfile.email),
          (this.dataToSend.protected = true);
        this.loading = true;
        dataServiceVD
          .exportUsersByProjectCodes(this.dataToSend)
          .then(() => {
            this.loading = false;
            this.$store.state.showDialogMessage = true;
            this.$store.state.dialogMessageTitle = "Export Started";
            this.$store.state.dialogMessageText = `Your file will be delivered on ${this.$store.state.userProfile.email}`;
          })
          .catch((error) => {
            /* eslint-disable-next-line */
            console.log(error);
            this.loading = false;
          });
      }
    },

    loadUsers(pageNumber) {
      this.userList.page = pageNumber ? pageNumber : this.nextPage;

      this.$setLoader();
      dataServiceVD
        .getProjectUsers({
          page: this.userList.page,
          projectCodes: this.dataToSend.projectCodes,
          startDate: this.dataToSend.startDate,
          endDate: this.dataToSend.endDate,
        })
        .then((r) => {
          this.users = r.data.concatData;
          this.nextPage = r.data.nextPage;
          this.totalDocs = r.data.totalDocs;
          this.$disableLoader();
        });
    },
    searchRespondent() {
      this.$setLoader();
      this.users = [];

      dataServiceVD
        .findUserByText({
          text: this.searchCriteria,
          projectCodes: this.dataToSend.projectCodes,
        })
        .then((r) => {
          this.users = r.data;
          this.$disableLoader();
        });
    },
  },
};
</script>